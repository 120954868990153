<template>
  <OnboardingStep
    :title="$t('onboarding.teamSetupView.title')"
    :next-text="$t('onboarding.teamSetupView.nextText')"
    v-bind="$props"
  >
    <TeamView v-bind="$props" :use-fab-add-button="false" />
  </OnboardingStep>
</template>

<script>
import { BaseStepView } from "./BaseStepView";
import TeamView from "../Team/AppView";

export default {
  name: "TeamSetupView",
  components: { TeamView },
  extends: BaseStepView,
};
</script>
